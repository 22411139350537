@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import "./assets/css/toastr.css";
@import "../node_modules/ngx-toastr/toastr.css";
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
.row {
  margin: auto 0;
}
i {
  cursor: pointer;
}
